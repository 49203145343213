<template>
  <div class="bs-default br-5 overflow-hidden mb-32">
    <div class="bg-form p-24">
      <div
        v-if="results.filter(v => v.visible).length === 1"
        class="p-relative br-5 overflow-hidden b-left b-right b-bottom bc-form-border"
      >
        <ProfileDetails
          class="bg-white br-5 overflow-hidden"
          v-for="res in results.filter(v => v.visible)"
          :node="!!textQuery ? res[textQuery] : res"
          :key="res.oid"
          big="true"
          :hide-checkbox="true"
        />
        <button
          @click.prevent="refreshResults"
          class="p-absolute z-2 top-0 right-0 bottom-0 transition color-text-lighter color-link_hover px-20"
        >
          <Icon name="trash-no-fill" />
        </button>
      </div>
      <div
        v-else
        class="p-relative"
      >
        <input
          type="text"
          class="input bg-white"
          :class="{ 'br-top-left-5 br-top-right-5 br-bottom-left-0 br-bottom-right-0': results.filter(v => v.visible).length > 1 }"
          placeholder="Type the subject's name, alias, company, education, or location"
          v-model="searchQuery"
          @keyup="textSearch"
        />
        <button class="p-absolute d-flex align-center jc-center top-0 right-0 bottom-0 w-48px">
          <Icon name="search" />
        </button>
      </div>
      <div
        v-if="results.filter(v => v.visible).length > 1"
        class="h-342px overflow-x-hidden custom-scrollbar bg-form b-left b-right b-bottom br-bottom-left-5 br-bottom-right-5 bc-form-border"
      >
        <ProfileDetails
          v-for="(res, index) in results.filter(v => v.visible)"
          :key="res.oid"
          :node="!!textQuery ? res[textQuery] : res"
          :filter="type"
          big="true"
          :hide-checkbox="true"
          :firstElem="index === 0"
          class="bg-white"
        />
      </div>
    </div>
    <div class="bg-white p-24">
      <div
        v-if="filter.scores.length && showFilter.scores"
        class="d-grid gtc-170px-auto mb-8"
      >
        <div class="text-h4 lh-36 color-text">Connection score</div>
        <div class="d-flex flex-w-wrap">
          <template
            v-for="item in filterScoresLimit"
            :key="item.value"
          >
            <button
              v-if="item.count"
              class="bttn-search mr-8 mb-8"
              :class="{ 'is-active': item.checked }"
              @click.prevent="checkFilter('scores', item.value)"
            >
              {{ item.title }} ({{ item.count }})
            </button>
          </template>

          <button
            v-if="filter.scores.length > 4"
            class="bttn-search transparent mr-8 mb-8"
            @click.prevent="showMoreScores = !showMoreScores"
          >
            {{ showMoreScores ? 'Show less' : 'Show more' }}
          </button>
        </div>
      </div>
      <div
        v-if="filter.tags.length && showFilter.tags"
        class="d-grid gtc-170px-auto mb-8"
      >
        <div class="text-h4 lh-36 color-text">Relation tags</div>
        <div class="d-flex flex-w-wrap">
          <template
            v-for="item in filterTagsLimit"
            :key="item.value"
          >
            <button
              v-if="item.count"
              class="bttn-search mr-8 mb-8"
              :class="{ 'is-active': item.checked }"
              @click.prevent="checkFilter('tags', item.value)"
            >
              {{ item.title }} ({{ item.count }})
            </button>
          </template>

          <button
            v-if="filter.tags.length > 4"
            class="bttn-search transparent mr-8 mb-8"
            @click.prevent="showMoreTags = !showMoreTags"
          >
            {{ showMoreTags ? 'Show less' : 'Show more' }}
          </button>
        </div>
      </div>
      <div
        v-if="filter.socialNetworks.length && showFilter.socialNetworks"
        class="d-grid gtc-170px-auto mb-8"
      >
        <div class="text-h4 lh-36 color-text">Social networks</div>
        <div class="d-flex flex-w-wrap">
          <template
            v-for="item in filter.socialNetworks"
            :key="item.value"
          >
            <button
              v-if="item.count"
              class="bttn-search mr-8 mb-8"
              :class="{ 'is-active': item.checked }"
              @click.prevent="checkFilter('socialNetworks', item.value)"
            >
              <font-awesome-icon :icon="['fab', item.icon]" />
              {{ item.title }} ({{ item.count }})
            </button>
          </template>
        </div>
      </div>
      <div
        v-if="filter.locations.length && showFilter.locations"
        class="d-grid gtc-170px-auto mb-8"
      >
        <div class="text-h4 lh-36 color-text">Location</div>
        <div class="d-flex flex-w-wrap">
          <template
            v-for="item in filterLocationsLimit"
            :key="item.value"
          >
            <button
              v-if="item.count"
              class="bttn-search mr-8 mb-8"
              :class="{ 'is-active': item.checked }"
              @click.prevent="checkFilter('locations', item.value)"
            >
              {{ item.title }} ({{ item.count }})
            </button>
          </template>

          <button
            v-if="filter.locations.length > 4"
            class="bttn-search transparent mr-8 mb-8"
            @click.prevent="showMoreLocations = !showMoreLocations"
          >
            {{ showMoreLocations ? 'Show less' : 'Show more' }}
          </button>
        </div>
      </div>
      <button
        v-if="filterActive"
        @click.prevent="resetFilter"
        class="d-flex align-center text-link mt-30"
      >
        <span class="mr-8">Cancel selection</span>
        <Icon name="cancel-btn-new" />
      </button>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';
import ProfileDetails from '@/components/UI/ProfileDetails';
import { mapActions } from 'vuex';
import { getProfileLocation } from '@/utils/helpers';

export default {
  components: {
    Icon,
    ProfileDetails
  },
  props: {
    getters: {
      default: {}
    },
    textQuery: {
      default: null
    },
    type: {
      default: 'profile'
    },
    showFilter: {
      default: { scores: false, tags: false, socialNetworks: true, locations: true }
    }
  },
  data () {
    return {
      currentRes: {},
      visibleRes: {},

      searchQuery: '',
      results: [],
      visibleOIDs: [],
      preFilter: {
        scores: [],
        tags: [],
        socialNetworks: {},
        locations: {}
      },
      filter: {
        scores: [],
        tags: [],
        socialNetworks: [],
        locations: []
      },
      showMoreScores: false,
      showMoreTags: false,
      showMoreLocations: false
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.currentRes = this.getters.current;
        this.visibleRes = this.getters.visible;

        this.resetFilter();
      }
    },
    getters: {
      deep: true,
      handler () {
        this.currentRes = this.getters.current;
        this.visibleRes = this.getters.visible;
      }
    },
    currentRes: {
      deep: false,
      immediate: true,
      handler () {
        this.filter.scores = [];
        this.preFilter.scores = [];
        this.filter.tags = [];
        this.preFilter.tags = [];
        this.filter.socialNetworks = [];
        this.preFilter.socialNetworks = {};
        this.filter.locations = [];
        this.preFilter.locations = [];
        this.searchQuery = '';
        this.results = [];
        this.visibleOIDs = [];
        this.showMoreScores = false;
        this.showMoreTags = false;
        this.showMoreLocations = false;

        this.updateFilter();
      }
    }
  },
  computed: {
    filterScoresLimit () {
      if (this.showMoreScores) return this.filter.scores;
      else return this.filter.scores.slice(0, 4);
    },
    filterTagsLimit () {
      if (this.showMoreTags) return this.filter.tags;
      else return this.filter.tags.slice(0, 4);
    },
    filterLocationsLimit () {
      if (this.showMoreLocations) return this.filter.locations;
      else return this.filter.locations.slice(0, 4);
    },
    filterActive () {
      return this.filter.scores.some(filter => filter.checked) || this.filter.tags.some(filter => filter.checked) || this.filter.locations.some(filter => filter.checked) || this.filter.socialNetworks.some(filter => filter.checked);
    }
  },
  methods: {
    ...mapActions(['setUpdateResults', 'setUpdateRelations']),

    addMoreInfo (target, title, icon) {
      if (this.preFilter.socialNetworks[target]) {
        this.preFilter.socialNetworks[target].title = title;
        this.preFilter.socialNetworks[target].icon = icon;
        this.preFilter.socialNetworks[target].value = target;
        this.filter.socialNetworks.push(this.preFilter.socialNetworks[target]);
      }
    },
    updateFilter () {
      this.visibleRes.forEach(r => {
        let scoresResult = 1;
        let socialResult = 1;
        let locationResult = 1;
        r.checked = false;
        r.visible = true;

        if (this.showFilter.scores) {
          if (this.preFilter.scores[r.weight]) scoresResult = this.preFilter.scores[r.weight].count + 1;
          this.preFilter.scores[r.weight] = { checked: false, count: scoresResult };
        }

        if (this.showFilter.tags) {
          const tags = r[this.textQuery].fields.connection_types.split(',');
          tags.forEach(tag => {
            let tagsResult = 1;

            if (this.preFilter.tags[tag]) tagsResult = this.preFilter.tags[tag].count + 1;
            this.preFilter.tags[tag] = { checked: false, count: tagsResult };
          });
        }

        if (this.showFilter.socialNetworks) {
          if (this.preFilter.socialNetworks[r.typeid]) socialResult = this.preFilter.socialNetworks[r.typeid].count + 1;
          this.preFilter.socialNetworks[r.typeid] = { checked: false, count: socialResult };
        }

        if (this.showFilter.locations) {
          let locationTitle = getProfileLocation(r);
          if (locationTitle === '') locationTitle = 'No info';
          if (this.preFilter.locations[locationTitle]) locationResult = this.preFilter.locations[locationTitle].count + 1;
          this.preFilter.locations[locationTitle] = { checked: false, count: locationResult };
        }
      });

      if (this.showFilter.scores) {
        for (const score in this.preFilter.scores) {
          const result = { ...this.preFilter.scores[score], title: score, value: score };
          this.filter.scores.push(result);
        }

        this.filter.scores.sort((a, b) => Number(b.title) - Number(a.title));
      }

      if (this.showFilter.tags) {
        for (const tag in this.preFilter.tags) {
          const result = { ...this.preFilter.tags[tag], title: tag, value: tag };
          this.filter.tags.push(result);
        }

        this.filter.tags.sort((a, b) => b.count - a.count);
      }

      if (this.showFilter.socialNetworks) {
        this.addMoreInfo('maltego.facebook.profile', 'Facebook', 'facebook-f');
        this.addMoreInfo('maltego.linkedin.profile', 'LinkedIn', 'linkedin-in');
        this.addMoreInfo('maltego.instagram.profile', 'Instagram', 'instagram');
        this.addMoreInfo('maltego.affiliation.Twitter', 'Twitter', 'twitter');
      }

      if (this.showFilter.locations) {
        for (const loc in this.preFilter.locations) {
          const result = { ...this.preFilter.locations[loc], title: loc, value: loc };
          this.filter.locations.push(result);
        }

        this.filter.locations.sort((a, b) => b.count - a.count);
      }
    },
    checkFilter (arr, val) {
      const isChecked = this.filter[arr].find(s => s.value === val).checked;
      this.filter[arr].find(s => s.value === val).checked = !isChecked;

      const scores = [];
      this.filter.scores.filter(s => s.checked).forEach(s => { scores.push(s.value); });

      const tags = [];
      this.filter.tags.filter(s => s.checked).forEach(s => { tags.push(s.value); });

      const socialNetworks = [];
      this.filter.socialNetworks.filter(s => s.checked).forEach(s => { socialNetworks.push(s.value); });

      const locations = [];
      this.filter.locations.filter(s => s.checked).forEach(s => { locations.push(s.value); });

      this.currentRes.forEach(ent => {
        let visibleScores = false;
        let visibleTags = false;
        let visibleNetworks = false;
        let visibleLocations = false;

        scores.forEach(s => { if (s === String(ent.weight)) visibleScores = true; });
        tags.forEach(s => { if (ent[this.textQuery].fields.connection_types.indexOf(s) > -1) visibleTags = true; });
        socialNetworks.forEach(s => { if (s === ent.typeid) visibleNetworks = true; });
        locations.forEach(l => {
          if (l === 'No info' && !getProfileLocation(ent)) visibleLocations = true;
          else if (l === getProfileLocation(ent)) visibleLocations = true;
        });

        if (!scores.length) visibleScores = true;
        if (!tags.length) visibleTags = true;
        if (!socialNetworks.length) visibleNetworks = true;
        if (!locations.length) visibleLocations = true;

        const visible = visibleScores && visibleTags && visibleNetworks && visibleLocations;
        ent.visible = visible;
      });

      if (this.type === 'profiles') this.setUpdateResults(this.currentRes);
      else this.setUpdateRelations(this.currentRes);
    },
    textSearch () {
      this.results = [];
      this.visibleOIDs = [];
      if (!this.searchQuery) {
        return;
      }
      const visibleResults = this.visibleRes;
      const searchFields = [
        'name', 'alias', 'info', 'created_at', 'location', 'person.name', 'twitter.screen-name',
        'description', 'experience', 'industry', 'occupation', 'lives', 'location_name'
      ];
      const searchQuery = this.searchQuery.toLowerCase();
      visibleResults.forEach(ent => {
        searchFields.forEach(fld => {
          let profile = ent;
          if (this.textQuery) profile = ent[this.textQuery];

          const field = profile.fields[fld];
          if (field && (field || '').toLowerCase().includes(searchQuery) && !(this.visibleOIDs.includes(ent.oid))) {
            this.results.push(ent);
            this.visibleOIDs.push(ent.oid);
          }
        });
      });
    },
    refreshResults () {
      this.searchQuery = '';
      this.results = [];
      this.visibleOIDs = [];
      const results = this.currentRes.forEach(result => {
        result.visible = true;
        result.checked = false;
      });
      if (this.type === 'profiles') this.setUpdateResults(results);
      else this.setUpdateRelations(results);
    },
    resetFilter () {
      this.filter.scores.forEach(score => { score.checked = false; });
      this.filter.tags.forEach(tag => { tag.checked = false; });
      this.filter.socialNetworks.forEach(social => { social.checked = false; });
      this.filter.locations.forEach(location => { location.checked = false; });
      this.refreshResults();
    }
  }
};
</script>
