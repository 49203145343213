<template>
  <div class="controller p-fixed z-1000 bs-default br-5 right-56px bottom-32px d-grid w-100">
    <a
      @click.prevent="closeController"
      class="p-absolute d-block w-16px h-16px cursor-pointer text-link color-white top-32px right-24px"
    >
      <Icon
        name="cancel-btn-new"
        class="w-100 h-100"
      />
    </a>

    <div class="bg-form b-top b-left b-bottom bc-form-border b-top-left-5 b-top-right-5 w-100 p-24">
      <div class="fs-22 lh-32 fw-600 color-link let-space-025 mb-16">{{ leftSide.title }}</div>
      <div class="fs-14 fw-600 color-text-lighter let-space-08 lh-20 mb-20">{{ leftSide.subtitle }}</div>
      <div class="d-flex align-center">
        <TagTooltip
          class="mr-32"
          :profiles="profiles"
        />
        <button
          v-if="leftSide.profilesButton"
          class="text-link color-text color-link_hover opacity-5"
          disabled
        >
          <Icon name="avatar-icon" class="mr-12" />
          Find profiles (12 slp)
        </button>
      </div>
    </div>
    <div class="bg-link br-top-right-5 br-bottom-right-5 p-24">
      <div class="fs-22 lh-32 fw-600 let-space-025 color-white mb-16">{{ rightSide.title }}</div>
      <div class="fs-14 fw-600 let-space-08 color-white lh-20 mb-20">{{ rightSide.subtitle }}</div>
      <div class="d-flex align-center">
        <button
          v-if="rightSide.exploreConnectionsButton && hasConnections"
          class="text-link color-white"
          @click.prevent="openConnections"
        >
          <Icon name="social-graph" class="mr-12" />
          Open connections
        </button>
        <button
          v-if="rightSide.exploreConnectionsButton && !hasConnections"
          class="text-link color-white"
          @click.prevent="findConnectionsMethod"
        >
          <Icon name="social-graph" class="mr-12" />
          Explore connections (15 SLP)
        </button>
        <button
          v-if="rightSide.findConnectionsButton.visible"
          :disabled="rightSide.findConnectionsButton.disabled"
          class="text-link color-white"
          :class="{ 'opacity-5': rightSide.findConnectionsButton.disabled }"
        >
          <Icon name="social-graph" class="mr-12" />
          Find connections
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TagTooltip from '@/components/UI/TagTooltip';
import Icon from '@/components/app/Icon';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    TagTooltip,
    Icon
  },
  props: {
    profiles: {
      default: []
    },
    type: {
      default: 'profiles'
    }
  },
  watch: {
    $route () {
      this.CLEAR_CHECKED_VALUES();
    }
  },
  computed: {
    ...mapGetters(['getConnections']),

    leftSide () {
      const content = {};
      if (this.type === 'profiles') {
        content.title = `${this.profiles.length} profile${this.profiles.length > 1 ? 's' : ''}`;
        content.tagButton = true;
        if (this.profiles.length === 1) content.profilesButton = true;
        else content.profilesButton = false;
      } else {
        content.title = `${this.profiles.length} connection${this.profiles.length > 1 ? 's pairs' : ' pair'}`;
      }
      content.subtitle = 'You can tag these results for inclusion in a report, or expand this profile further.';

      return content;
    },
    rightSide () {
      const content = {};
      if (this.type === 'profiles') {
        if (this.profiles.length === 1) {
          content.title = 'Get closest contacts';
          content.subtitle = 'Get more info about the closest contacts of this profile';
          content.exploreConnectionsButton = true;
          content.findConnectionsButton = { visible: false, disabled: true };
        } else {
          content.title = 'Try later';
          content.subtitle = 'The connection search is currently unavailable. Work in progress.';
          content.exploreConnectionsButton = false;
          content.findConnectionsButton = { visible: true, disabled: true };
        }
      } else {
        content.title = 'Try later';
        content.subtitle = 'The connection search is currently unavailable. Work in progress.';
        content.exploreConnectionsButton = false;
        content.findConnectionsButton = { visible: true, disabled: true };
      }

      return content;
    },

    hasConnections () {
      if (this.getConnections) {
        const profileTarget = this.profiles[0].oid;
        let target = false;
        this.getConnections.forEach(connection => {
          const ifConnection = connection.targets.find(target => target === profileTarget);
          if (ifConnection) target = connection.id;
        });
        return target;
      } else return false;
    }
  },
  methods: {
    ...mapActions(['setUpdateResults', 'findConnections']),
    ...mapMutations(['CLEAR_CHECKED_VALUES']),

    closeController () {
      this.CLEAR_CHECKED_VALUES();
    },
    openConnections () {
      let caseId = this.$route.params.id;
      if (this.profiles[0].cid) caseId = this.profiles[0].cid;
      this.$router.push(`/case/${caseId}/${this.profiles[0].rid}/${this.hasConnections}`);
    },
    async findConnectionsMethod () {
      await this.findConnections(this.profiles);
    }
  }
};
</script>

<style lang="scss" scoped>
  .controller {
    max-width: 725px;
    grid-template-columns: auto 320px;
  }
</style>
